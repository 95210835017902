<template>

<div class="name">

	<input ref="name" v-model="name" class="name-input" type="text" tabindex="1" placeholder="Your last name" />

</div>

</template>

<script>

export default {

	data: function() {

		return {
			name: ''
		}

	},

	watch: {

		name: function(n) {

			this.$emit('change', n)

		}

	},

	created: function() {

		this.$nextTick(function() {
			
			this.$refs.name.focus()
		
		}.bind(this))

	}

}

</script>

<style>

.name {
	width: 290px;
	margin: 0px 5px 20px 5px;
}

.name-input {
	height: 40px;
	background-color: #b9c8ff;
	width: 100%;
	color: #4056A6;
	text-align: center;
	border: 0;
	padding: 0px;
	transition: all 100ms linear;
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	clip-path: polygon(6px 0, 284px 0, 100% 6px, 100% 34px, 284px 100%, 6px 100%, 0% 34px, 0% 6px);
}

.name-input::placeholder {
	color: #4056A6;
	font-weight: 300;
}

.name-input:focus {
	outline: 0;
	filter: brightness(0.8);
}

</style>
