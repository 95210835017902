<template>
	
<div class="badge">

	<com-qr :token="data.token" />

	<div class="badge-summary">

		<div class="badge-summary-photo" :style="{backgroundImage: 'url(' + data.photo + ')'}"></div>
		<div class="badge-summary-text">{{ data.firstname }} {{ data.lastname }}</div>

	</div>

	<div class="badge-forget" v-on:click="$emit('forget')">tap here to forget this code</div>

</div>

</template>

<script>

import comQR from './badge/QR'

export default {

	props: ['data'],

	components: {
		'com-qr': comQR
	}
	
}

</script>

<style scoped>

.badge-summary {
	margin-top: 68px;
	position: relative;
}

.badge-summary-photo {
	position: absolute;
	left: 50%;
	top: -48px;
	background-position: 50% 50%;
	background-size: cover;
	margin-left: -48px;
	width: 96px;
	height: 96px;
	background-color: #ddd;
	z-index: 2;
	margin-bottom: 20px;
	clip-path: polygon(6px 0, calc(100% - 6px) 0, 100% 6px, 100% calc(100% - 6px), calc(100% - 6px) 100%, 6px 100%, 0% calc(100% - 6px), 0% 6px);
}

.badge-summary-text {
	font-size: 20px;
	color: #fff;
	padding: 68px 10px 20px 10px;
	text-align: center;
	background-color: #4056A6;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	clip-path: polygon(6px 0, calc(100% - 6px) 0, 100% 6px, 100% calc(100% - 6px), calc(100% - 6px) 100%, 6px 100%, 0% calc(100% - 6px), 0% 6px);
}

.badge-forget {
	font-size: 16px;
	color: #999;
	font-family: 'Open Sans', sans-serif;
	text-align: center;
	height: 20px;
	margin-top: 20px;
}

</style>