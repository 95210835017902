import './assets/reset.css'

import Vue from 'vue'
import App from './App.vue'

import mixWindow from './mixin/window'

Vue.config.productionTip = false

Vue.mixin(mixWindow)

new Vue({
  render: h => h(App),
}).$mount('#app')
