<template>

<div class="verify" :class="{'is-loading': is.loading}">

	<div class="verify-logo"></div>

	<div class="verify-text" :class="{'is-invalid': is.invalid}">
		
		<template v-if="!is.invalid">Enter your last name and six digit pin to generate your QR code. You will only need to do this once.</template>
		<template v-if="is.invalid">Sorry, your name and/or pin were not recognised. Please try again.</template>

	</div>

	<com-name v-on:change="onLastnameChange" />
	<com-pin v-on:change="onPinChange" v-on:pinComplete="onPinComplete" />

	<button ref="submit" class="verify-button" tabindex="8" v-on:click="onSubmitClick"><span>Generate</span></button>

</div>

</template>

<script>

import comName from './verify/Name.vue'
import comPin from './verify/Pin.vue'
import axios from 'axios'

export default {

	components: {
		'com-name': comName,
		'com-pin': comPin
	},

	data: function() {

		return {
			is: {
				loading: false,
				invalid: false
			},
			pin: '',
			lastname: ''
		}

	},

	methods: {

		onLastnameChange: function(lastname) {

			this.lastname = lastname

		},

		onPinChange: function(pin) {

			this.pin = pin

		},

		onPinComplete: function() {

			this.$refs.submit.focus()
		
		},

		onSubmitClick: function() {

			this.is.loading = true

			axios({
				method: 'post',
				url: 'https://backend.meepleville.codelish.com/api/generate',
				data: {
					pin: this.pin,
					lastname: this.lastname
				}
			}).then(function(response) {

				this.$emit('verified', response.data)

			}.bind(this)).catch(function() {

				this.is.invalid = true
				this.is.loading = false
				this.$refs.submit.blur()

			}.bind(this))

		}

	}

}

</script>

<style>

.verify {
	width: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.verify-logo {
	width: 240px;
	height: 200px;
	background-image: url(~@/assets/logo.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 0%;
	margin-bottom: 0px;
}

.verify-text {
	font-size: 16px;
	color: #000;
	line-height: 20px;
	text-align: center;
	height: 60px;
	margin: 0px 5px;
	margin-bottom: 20px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
	box-sizing: border-box;
}

.verify-text.is-invalid {
	background-color: #f68a8a;
	padding: 10px;
	clip-path: polygon(6px 0, calc(100% - 6px) 0, 100% 6px, 100% calc(100% - 6px), calc(100% - 6px) 100%, 6px 100%, 0% calc(100% - 6px), 0% 6px);
}

.verify-button {
	margin-top: 30px;
	height: 40px;
	background-color: #4056A6;
	color: #fff;
	font-size: 16px;
	width: 120px;
	border: 0px;
	cursor: pointer;
	transition: all 100ms linear;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	clip-path: polygon(6px 0, calc(100% - 6px) 0, 100% 6px, 100% calc(100% - 6px), calc(100% - 6px) 100%, 6px 100%, 0% calc(100% - 6px), 0% 6px);
}

.verify-button:focus {
	transform: scale(1.05);
}

.verify.is-loading {
	pointer-events: none;
}

.verify.is-loading .verify-button {
	background-image: url(~@/assets/load.button.gif);
}

.verify.is-loading .verify-button span {
	visibility: hidden;
}

</style>
