<template>

<div id="app" :class="{'is-loading': is.loading}">

	<com-badge :data="data" v-on:forget="onForget" v-if="is.verified" />
	<com-verify v-if="!is.verified" v-on:verified="onVerified" />

</div>

</template>

<script>

import comBadge from './components/Badge'
import comVerify from './components/Verify'

import ls from 'local-storage'

export default {

	name: 'App',

	components: {
		'com-verify': comVerify,
		'com-badge': comBadge
	},

	data: function() {

		return {
			is: {
				verified: false,
				loading: true
			},
			data: {
				firstname: '',
				lastname: '',
				photo: '',
				token: ''
			}
		}

	},

	created: function() {

		if (ls.get('data') !== null) {

			this.data = ls.get('data')
			this.is.verified = true

		}

		this.is.loading = false

	},

	methods: {

		onForget: function() {

			this.is.verified = false
			ls.remove('data')

		},

		onVerified: function(data) {

			ls.set('data', data)

			this.data = data
			this.is.verified = true

		}

	}

}

</script>

<style>

body {
	overflow: hidden;
	background-color: #fff;
	height: calc(100vh - var(--vh-offset, 0px));
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
}

#app {
	overflow: hidden;
	opacity: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 480px;
	transition: all 300ms ease-in-out;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

#app.is-loading {
	background-image: url(~@/assets/load.app.gif);
}

</style>
