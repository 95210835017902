<template>
	
<div ref="qr" class="qr">

</div>

</template>

<script>

import * as qrcode from 'easyqrcodejs'

export default {

	props: ['token'],

	created: function() {

		this.$nextTick(function() {
			
			new qrcode(this.$refs.qr, {
				text: this.token,
				width: 290,
				height: 290,
				dotScale: 0.85,
				dotScaleA: 0.85,
				dotScaleTiming: 0.85,
				colorDark: '#4056A6',
				colorLight: '#ffffff',
				PO: '#4056A6',
				PI: '#4056A6',
				AO: '#4056A6',
				AI: '#4056A6'
			})

		}.bind(this))

	}
	
}

</script>

<style scoped>

</style>
