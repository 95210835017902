<template>

<div class="pin">

	<div class="pin-input">

		<input ref="digit" v-for="index in 6" v-model="pin[index - 1]" v-on:keydown="onKeydown($event, index)" :key="index" v-on:keyup="onChange($event, index)" class="pin-input-digit" type="tel" placeholder="0" :tabindex="index + 1" />

	</div>

</div>

</template>

<script>

import Vue from 'vue'

export default {

	data: function() {

		return {
			pin: ['', '', '', '', '', '']
		}

	},

	methods: {

		onKeydown: function(event, index) {
			
			if (event.key !== 'Tab') Vue.set(this.pin, index - 1, '')

		},

		onChange: function(event, index) {

			this.$emit('change', this.pin.join(''))

			if (this.pin[index - 1] !== '' && event.key !== 'Tab') {

				if (index < 6) {
					
					this.$refs.digit[index].focus()

				} else {

					this.$emit('pinComplete')

				}

			}

		}

	}

}

</script>

<style>

.pin {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.pin-label {
	font-size: 16px;
	line-height: 20px;
	font-weight: 300;
	text-align: center;
	margin-bottom: 20px;
}

.pin-input {
	display: flex;
	justify-content: center;
}

.pin-input-digit {
	height: 40px;
	border: 0px;
	padding: 0px;
	background-color: #b9c8ff;
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
	color: #4056A6;
	font-weight: 600;
	text-align: center;
	line-height: 40px;
	width: 40px;
	margin: 0px 5px;
	transition: all 100ms linear;
	clip-path: polygon(6px 0, 34px 0, 100% 6px, 100% 34px, 34px 100%, 6px 100%, 0% 34px, 0% 6px);
}

.pin-input-digit::-webkit-inner-spin-button, 
.pin-input-digit::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    margin: 0;
}

.pin-input-digit::placeholder {
	color: #4056A6;
	font-weight: 300;
}

.pin-input-digit:focus {
	outline: 0;
	transform: scale(1.05);
	filter: brightness(0.8);
}

</style>
